import Action from '@/Action'
import Hypothesis from '@/Hypothesis'

export default class Milestone {
    actions: Action[] = []
    completed: boolean
    hypotheses: Hypothesis[] = []
    id: string
    key: string
    name: string

    constructor(id: string, name: string, completed: boolean, key = '') {
        this.completed = completed
        this.id = id
        this.key = key
        this.name = name
    }
}
