
import { deleteDoc, doc } from 'firebase/firestore'
import { Options, Vue } from 'vue-class-component'

@Options({
})
export default class ProductList extends Vue {
    public get selectedProductId() {
        return this.$store.state.selectedProductId
    }

    public set selectedProductId(selectedProductId: string) {
        this.$router.push({ name: 'product', params: { productId: selectedProductId } })
    }

    public async deleteProduct(productId: string) {
        if (!confirm('Are you sure you want to delete this product?')) {
            return
        }
        const { firestore } = this.$store.getters
        await deleteDoc(doc(firestore, 'products', productId))
    }
}
