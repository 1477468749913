import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth'
import { onSnapshot, query, setDoc, where } from 'firebase/firestore'
import Quasar from 'quasar/src/vue-plugin.js';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import quasarUserOptions from './quasar-user-options'

const auth = getAuth()

let productsUnwatcher: (() => void) | undefined
let userStateUnwatcher: (() => void) | undefined
onAuthStateChanged(auth, async (user) => {
    productsUnwatcher?.()
    productsUnwatcher = undefined
    userStateUnwatcher?.()
    userStateUnwatcher = undefined

    if (user) {
        productsUnwatcher = onSnapshot(query(store.getters.productsCollection, where('ownerId', '==', user.uid)), (querySnapshot) => {
            store.commit('setProductList', querySnapshot.docs.map((doc) => ({ id: doc.id, ...(doc.data() as any) })))
        })
    } else {
        await signInAnonymously(auth)
    }
})

let selectedProductUnwatcher: (() => void) | undefined
router.beforeEach(async (to, _, next) => {
    if (to.params) {
        const { productId } = to.params
        if (productId !== store.state.selectedProductId) {
            store.commit('setSelectedProductId', productId)

            selectedProductUnwatcher?.()

            if (productId) {
                const selectedProductDoc = store.getters.firestoreProductDoc
                if (!selectedProductDoc) {
                    throw new Error('Cannot get product')
                }
                let stateLoaded = false
                selectedProductUnwatcher = onSnapshot(selectedProductDoc, (snapshot: any) => {
                    if (stateLoaded) {
                        return
                    }
                    stateLoaded = true
                    const snapshotData = snapshot.exists ? snapshot.data() : null
                    store.commit('setProductName', snapshotData?.name)
                    store.commit('setWizardState', snapshotData?.wizard)
                    store.commit('setMilestones', snapshotData?.milestones ?? [])
                })
            }
        }
    }
    next()
})

store.state.userState.setSaveMethod((state) => {
    const doc = store.getters.firestoreProductDoc
    if (doc) {
        setDoc(doc, { wizard: state }, { merge: true })
    }
})
store.watch(() => store.state.milestones, (milestones) => {
    const doc = store.getters.firestoreProductDoc
    if (doc) {
        setDoc(doc, { milestones: JSON.parse(JSON.stringify(milestones)) }, { merge: true })
    }
}, { deep: true })

createApp(App).use(Quasar, quasarUserOptions).use(store).use(router)
    .mount('#app')
