
import ProductList from '@/components/ProductList.vue'
import { doc, setDoc } from 'firebase/firestore'
import { v4 as uuid } from 'uuid'
import { Options, Vue } from 'vue-class-component'

@Options({
    components: {
        ProductList,
    },
})
export default class HomeView extends Vue {
    public async newProduct() {
        const { firestore, userId } = this.$store.getters
        if (userId) {
            const id = uuid()
            await setDoc(doc(firestore, 'products', id), { name: this.nextUntitledProductName(), ownerId: userId })
            this.$router.push({ name: 'product', params: { productId: id } })
        }
    }

    private nextUntitledProductName() {
        // Find an untitled product name that doesn't exist yet.
        let i = 1
        while (this.$store.state.productList.find((product: any) => product.name === `Untitled ${i}`)) {
            i += 1
        }
        return `Untitled ${i}`
    }
}

