
import { setDoc } from 'firebase/firestore'
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class App extends Vue {
    public hideDebugControls = false

    public get productName() {
        return this.$store.state.productName
    }

    public set productName(productName: string) {
        const doc = this.$store.getters.firestoreProductDoc
        if (doc) {
            setDoc(doc, { name: productName }, { merge: true })
            this.$store.commit('setProductName', productName)
        }
    }
}
