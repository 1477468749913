
import { v4 as uuid } from 'uuid'
import { PropType } from 'vue'
import { Options, Vue } from 'vue-class-component'
import Actions from '@/components/Actions.vue'
import Hypotheses from '@/components/Hypotheses.vue'
import Milestone from '@/Milestone'
import Wizard from '@/components/Wizard.vue'

@Options({
    components: {
        Actions,
        Hypotheses,
        Wizard,
    },
    props: {
        productId: {
            type: String as PropType<string>,
        },
    },
})
export default class ProductView extends Vue {
    public milestoneTab = 'overview'
    public selectedMilestoneId: string | null = null

    public get editingEnabled() {
        return this.$store.state.milestoneEditingEnabled
    }

    public get hypothesisEditingEnabled() {
        return this.$store.state.hypothesisEditingEnabled
    }

    public get milestones(): Milestone[] {
        return this.$store.state.milestones
    }

    public get selectedMilestone() {
        return this.milestones.find(({ id }) => id === this.selectedMilestoneId)
    }

    public selectMilestoneId(id: string) {
        if (this.editingEnabled) {
            if (this.selectedMilestoneId === id) {
                this.selectedMilestoneId = null
            } else {
                this.selectedMilestoneId = id
                this.milestoneTab = 'overview'
            }
        }
    }

    public get canMoveSelectedEarlier() {
        return this.selectedMilestone && this.selectedMilestoneId !== this.milestones[0].id
    }

    public get canMoveSelectedLater() {
        return this.selectedMilestone && this.selectedMilestoneId !== this.milestones[this.milestones.length - 1].id
    }

    public moveSelectedEarlier() {
        if (this.selectedMilestone) {
            const idx = this.milestones.findIndex(({ id }) => id === this.selectedMilestoneId)
            const tmp = this.milestones[idx - 1]
            this.milestones[idx - 1] = this.milestones[idx]
            this.milestones[idx] = tmp
        }
    }

    public moveSelectedLater() {
        if (this.selectedMilestone) {
            const idx = this.milestones.findIndex(({ id }) => id === this.selectedMilestoneId)
            const tmp = this.milestones[idx + 1]
            this.milestones[idx + 1] = this.milestones[idx]
            this.milestones[idx] = tmp
        }
    }

    public deleteSelected() {
        if (!this.selectedMilestone) {
            return
        }

        this.$q.dialog({
            title: 'Delete milestone',
            message: 'Are you sure you want to delete this milestone?',
            cancel: true,
            persistent: true,
        }).onOk(() => {
            // Delete
            this.milestones.splice(this.milestones.findIndex(({ id }) => id === this.selectedMilestoneId), 1)
            this.selectedMilestoneId = null
        })
    }

    private static constructMilestone() {
        return new Milestone(uuid(), 'New milestone', false)
    }

    public addMilestoneAtIndex(idx: number) {
        const milestone = ProductView.constructMilestone()
        this.milestones.splice(idx, 0, milestone)
        this.selectedMilestoneId = milestone.id
    }

    private appendMilestone() {
        const milestone = ProductView.constructMilestone()
        this.milestones.push(milestone)
        this.selectedMilestoneId = milestone.id
    }

    public isIncompleteBeforeComplete(id: string) {
        const idx = this.milestones.findIndex((milestone) => milestone.id === id)
        const milestone = this.milestones[idx]
        if (milestone.completed) {
            return false
        }
        return this.milestones.slice(idx + 1).some(({ completed }) => completed)
    }

    public milestoneHasWarning(id: string) {
        return this.isIncompleteBeforeComplete(id)
    }
}
