import ScriptRunner from '@mattc/expert/scriptRunner'
import UserState from '@mattc/expert/userState'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { collection, doc, getFirestore } from 'firebase/firestore'
import { createStore } from 'vuex'
import Milestone from '@/Milestone'

const firebase = initializeApp({
    apiKey: 'AIzaSyDa0Uoui4YMnw40DEB7WmpN_DoXbuyC3OA',
    authDomain: 'impactdrivendevelopment.firebaseapp.com',
    projectId: 'impactdrivendevelopment',
    storageBucket: 'impactdrivendevelopment.appspot.com',
    messagingSenderId: '482801064185',
    appId: '1:482801064185:web:ca265441c122cb5deeea28',
    measurementId: 'G-F7187Y0L5H',
})
const userState = new UserState()

export default createStore({
    state: {
        firebase,
        hypothesisEditingEnabled: false,
        milestoneEditingEnabled: false,
        milestones: [] as Milestone[],
        productList: [] as any[],
        productName: null,
        scriptRunner: new ScriptRunner(userState),
        selectedProductId: null,
        userState,
        wizardEnabled: true,
    },
    getters: {
        allFeaturesEnabled(state) {
            return state.milestoneEditingEnabled && state.hypothesisEditingEnabled
        },
        firestore(state) {
            return getFirestore(state.firebase)
        },
        firestoreProductDoc(state, getters) {
            const { firestore } = getters
            const { selectedProductId } = state
            if (selectedProductId) {
                return doc(firestore, 'products', selectedProductId)
            }
            return null
        },
        hasMilestoneWithKey(state) {
            return (key: string) => state.milestones.some((milestone) => milestone.key === key)
        },
        hasMilestones(state) {
            return state.milestones.length > 0
        },
        productsCollection(state) {
            return collection(getFirestore(state.firebase), 'products')
        },
        userId() {
            return getAuth().currentUser?.uid
        },
    },
    mutations: {
        addMilestone(state, milestone: Milestone) {
            state.milestones.push(milestone)
        },
        toggleWizard(state) {
            state.wizardEnabled = !state.wizardEnabled
        },
        enableAllFeatures(state) {
            state.milestoneEditingEnabled = true
            state.hypothesisEditingEnabled = true
        },
        enableMilestoneEditing(state) {
            state.milestoneEditingEnabled = true
        },
        enableHypothesisEditing(state) {
            state.hypothesisEditingEnabled = true
        },
        insertMilestone(state, { beforeKey, milestone }: { beforeKey: string, milestone: Milestone }) {
            const index = state.milestones.findIndex(({ key }) => key === beforeKey)
            state.milestones.splice(index, 0, milestone)
        },
        setMilestones(state, milestones: Milestone[]) {
            state.milestones = milestones.map((milestone: Milestone) => Object.assign(new Milestone('', '', false), milestone))
        },
        setProductList(state, productList) {
            state.productList = productList
        },
        setProductName(state, productName) {
            state.productName = productName
        },
        setSelectedProductId(state, productId) {
            state.selectedProductId = productId
            if (!productId) {
                state.productName = null
            }
        },
        setWizardState(state, wizardState) {
            state.userState.setState(wizardState)
            if (wizardState && wizardState.variables?.milestonesExplained) {
                state.milestoneEditingEnabled = true
                state.hypothesisEditingEnabled = true
            }
        },
    },
    actions: {
    },
    modules: {
    },
})
