
import { v4 as uuid } from 'uuid'
import { PropType } from 'vue'
import draggable from 'vuedraggable'
import { Options, Vue } from 'vue-class-component'
import Action from '@/Action'
import Milestone from '@/Milestone'
import SubAction from '@/SubAction'

@Options({
    components: {
        draggable,
    },
    props: {
        milestone: {
            type: Object as PropType<Milestone>,
            required: true,
        },
    },
})
export default class Actions extends Vue {
    public draftAction = ''
    public draftSubAction = ''
    public milestone!: Milestone
    public selectedActionId: string | null = null
    public showCompleted = false

    public get hasIncompleteActions() {
        return this.hasIncompleteImportantActions || this.hasIncompleteUnimportantActions
    }

    public get hasCompletedActions() {
        return this.milestone.actions.some((action) => action.completed)
    }

    public get hasIncompleteImportantActions() {
        return this.importantActions.some((action) => !action.completed)
    }

    public get hasIncompleteUnimportantActions() {
        return this.unimportantActions.some((action) => !action.completed)
    }

    public get importantActions() {
        return this.milestone.actions.filter((action) => action.important)
    }

    public get unimportantActions() {
        return this.milestone.actions.filter((action) => !action.important)
    }

    public get actionsList() {
        return [...this.importantActions, { id: 'importantBar' }, ...this.unimportantActions]
    }

    public set actionsList(actionList: Action[] | { id: string }[]) {
        const importantBarIndex = actionList.findIndex((action) => action.id === 'importantBar')
        const beforeImportantBar = actionList.slice(0, importantBarIndex) as Action[]
        const afterImportantBar = actionList.slice(importantBarIndex + 1) as Action[]
        beforeImportantBar.forEach((action) => {
            if (!action.important) {
                action.important = true
            }
        })
        afterImportantBar.forEach((action) => {
            if (action.important) {
                action.important = false
            }
        })
        this.milestone.actions = [...beforeImportantBar, ...afterImportantBar]
    }

    public addAction() {
        if (this.draftAction !== '') {
            this.milestone.actions.push(new Action(uuid(), this.draftAction, false))
            this.draftAction = ''
        }
    }

    public get hypothesisOptions() {
        return this.milestone.hypotheses.map((hypothesis) => ({
            label: hypothesis.name,
            value: hypothesis.id,
        }))
    }

    public deleteSelected() {
        this.$q.dialog({
            title: 'Delete action',
            message: 'Are you sure you want to delete this action?',
            cancel: true,
            persistent: true,
        }).onOk(() => {
            if (!this.selectedAction) {
                return
            }
            const idx = this.milestone.actions.indexOf(this.selectedAction)
            if (idx > -1) {
                this.milestone.actions.splice(idx, 1)
            }
            this.selectedActionId = null
        })
    }

    public get selectedAction() {
        return this.milestone.actions.find((action) => action.id === this.selectedActionId)
    }

    public addSubAction() {
        if (this.draftSubAction !== '') {
            if (!this.selectedAction) {
                return
            }
            this.selectedAction.subActions.push(new SubAction(this.draftSubAction))
            this.draftSubAction = ''
        }
    }

    public deleteSubAction(subAction: SubAction) {
        this.$q.dialog({
            title: 'Delete sub-action',
            message: 'Are you sure you want to delete this sub-action?',
            cancel: true,
            persistent: true,
        }).onOk(() => {
            if (!this.selectedAction) {
                return
            }
            const idx = this.selectedAction.subActions.indexOf(subAction)
            if (idx > -1) {
                this.selectedAction.subActions.splice(idx, 1)
            }
        })
    }
}
