
import { v4 as uuid } from 'uuid'
import { PropType } from 'vue'
import draggable from 'vuedraggable'
import { Options, Vue } from 'vue-class-component'
import Action from '@/Action'
import Hypothesis from '@/Hypothesis'
import Milestone from '@/Milestone'

@Options({
    components: {
        draggable,
    },
    props: {
        milestone: {
            type: Object as PropType<Milestone>,
            required: true,
        },
    },
})
export default class Hypotheses extends Vue {
    private draftHypothesis = ''
    private milestone!: Milestone
    private selectedHypothesisId: string | null = null

    private get likelyHypotheses() {
        return this.milestone.hypotheses.filter((hypothesis) => hypothesis.likely)
    }

    private get unlikelyHypotheses() {
        return this.milestone.hypotheses.filter((hypothesis) => !hypothesis.likely)
    }

    private get hypothesisList() {
        return [...this.likelyHypotheses, { id: 'likelyBar' }, ...this.unlikelyHypotheses]
    }

    private set hypothesisList(hypothesisList: Hypothesis[] | { id: string }[]) {
        const likelyBarIndex = hypothesisList.findIndex((hypothesis) => hypothesis.id === 'likelyBar')
        const beforeLikelyBar = hypothesisList.slice(0, likelyBarIndex) as Hypothesis[]
        const afterLikelyBar = hypothesisList.slice(likelyBarIndex + 1) as Hypothesis[]
        beforeLikelyBar.forEach((hypothesis) => {
            if (!hypothesis.likely) {
                hypothesis.likely = true
            }
        })
        afterLikelyBar.forEach((hypothesis) => {
            if (hypothesis.likely) {
                hypothesis.likely = false
            }
        })
        this.milestone.hypotheses = [...beforeLikelyBar, ...afterLikelyBar]
    }

    private addHypothesis() {
        if (this.draftHypothesis !== '') {
            this.milestone.hypotheses.push(new Hypothesis(uuid(), this.draftHypothesis, false))
            this.draftHypothesis = ''
        }
    }

    private get selectedHypothesis(): Hypothesis | null {
        if (this.selectedHypothesisId !== null) {
            return this.milestone.hypotheses.find((hypothesis) => hypothesis.id === this.selectedHypothesisId) || null
        }
        return null
    }

    private get relevantActions() {
        if (this.selectedHypothesisId) {
            return this.milestone.actions.filter((action) => action.hypothesisIds?.includes(this.selectedHypothesisId!))
        }
        return []
    }

    private addAction(action: Action) {
        if (this.selectedHypothesisId) {
            if (!action.hypothesisIds) {
                action.hypothesisIds = []
            }
            action.hypothesisIds.push(this.selectedHypothesisId)
        }
    }

    private removeAction(action: Action) {
        if (this.selectedHypothesisId) {
            const idx = action.hypothesisIds.indexOf(this.selectedHypothesisId)
            if (idx > -1) {
                action.hypothesisIds.splice(idx, 1)
            }
        }
    }

    private deleteSelected() {
        this.$q.dialog({
            title: 'Delete hypothesis',
            message: 'Are you sure you want to delete this hypothesis?',
            cancel: true,
            persistent: true,
        }).onOk(() => {
            if (!this.selectedHypothesis) {
                return
            }
            const idx = this.milestone.hypotheses.indexOf(this.selectedHypothesis)
            if (idx > -1) {
                this.milestone.hypotheses.splice(idx, 1)
            }
            this.selectedHypothesisId = null
        })
    }
}
