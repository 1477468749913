import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AboutView from '@/views/AboutView.vue'
import HomeView from '@/views/HomeView.vue'
import ProductView from '@/views/ProductView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/products/:productId',
        name: 'product',
        component: ProductView,
        props: true,
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
