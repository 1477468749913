import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_ctx.hasActivePhase)
    ? (_openBlock(), _createBlock(_component_q_card, {
        key: 0,
        class: "card"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { class: "bg-secondary text-white" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.message, (messageLine, key) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "text-h6",
                  key: key
                }, _toDisplayString(messageLine), 1))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator, { dark: "" }),
          (_ctx.settingOther)
            ? (_openBlock(), _createBlock(_component_q_card_actions, { key: 0 }, {
                default: _withCtx(() => [
                  _createElementVNode("form", {
                    onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.selectRawAnswer()), ["prevent"]))
                  }, [
                    _createVNode(_component_q_input, {
                      type: "text",
                      modelValue: _ctx.otherAnswer,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.otherAnswer) = $event))
                    }, {
                      before: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          flat: "",
                          icon: "arrow_back",
                          color: "teal",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.settingOther = false))
                        })
                      ]),
                      after: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectRawAnswer())),
                          round: "",
                          disable: !_ctx.rawTextIsValid,
                          icon: "done",
                          size: "sm",
                          color: "teal"
                        }, null, 8, ["disable"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ], 32)
                ]),
                _: 1
              }))
            : (_ctx.activeScript.answerType === 'choice')
              ? (_openBlock(), _createBlock(_component_q_card_actions, {
                  key: 1,
                  class: "answer"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleAnswers, (choice, idx) => {
                      return (_openBlock(), _createBlock(_component_q_btn, {
                        flat: "",
                        key: idx,
                        color: "teal",
                        onClick: ($event: any) => (_ctx.selectAnswer(_ctx.scriptRunner.answerToAnswerText(choice)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(choice.text), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128)),
                    (_ctx.activeScript.includeOther)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          flat: "",
                          color: "teal",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.settingOther = true))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Other ")
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : (_ctx.activeScript.answerType === 'raw')
                ? (_openBlock(), _createBlock(_component_q_card_actions, { key: 2 }, {
                    default: _withCtx(() => [
                      _createElementVNode("form", {
                        onSubmit: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.selectRawAnswer()), ["prevent"]))
                      }, [
                        _createVNode(_component_q_input, {
                          type: "text",
                          modelValue: _ctx.otherAnswer,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.otherAnswer) = $event))
                        }, {
                          after: _withCtx(() => [
                            _createVNode(_component_q_btn, {
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectRawAnswer())),
                              round: "",
                              disable: !_ctx.rawTextIsValid,
                              icon: "done",
                              size: "sm",
                              color: "teal"
                            }, null, 8, ["disable"])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ], 32)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}