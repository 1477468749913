import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bc28283a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_ProductList = _resolveComponent("ProductList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_btn, {
      label: "New product",
      icon: "add",
      color: "teal",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.newProduct()))
    }),
    _createVNode(_component_ProductList)
  ]))
}