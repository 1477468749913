
import ScriptRunner from '@mattc/expert/scriptRunner'
import UserState from '@mattc/expert/userState'
import { v4 as uuid } from 'uuid'
import { Options, Vue } from 'vue-class-component'
import Milestone from '@/Milestone'

@Options({})
export default class Wizard extends Vue {
    private activeScript: any = null
    private hasActivePhase = false
    private otherAnswer = ''
    private settingOther = false
    private stateUnwatcher: null | (() => void) = null

    public mounted() {
        this.stateUnwatcher?.()
        this.updateScriptState()
        this.stateUnwatcher = this.scriptRunner.watchCurrentQuestion(() => this.updateScriptState())
    }

    public unmounted() {
        this.stateUnwatcher?.()
        this.stateUnwatcher = null
    }

    // Reactivity isn't working with Vue 3, so explicitly update states for now.
    private updateScriptState() {
        this.activeScript = this.scriptRunner.activeScript
        this.hasActivePhase = this.scriptRunner.hasActivePhase
    }

    private get userState(): UserState {
        return this.$store.state.userState
    }

    private get scriptRunner(): ScriptRunner {
        return this.$store.state.scriptRunner
    }

    public beforeMount() {
        this.scriptRunner.setScript([
            {
                answers: [
                    {
                        answer: 'users',
                        followup: 'cc5dcacc-4dac-4e1b-84fb-7d75ef0a4419',
                        text: 'Lots of users',
                    },
                    {
                        answer: 'revenue',
                        followup: '78a700ed-61dc-4114-9994-79957f91163e',
                        text: 'Plenty of revenue',
                    },
                    {
                        answer: 'mission',
                        text: 'Accomplishing a mission',
                        followup: 'b045e299-cf9c-433d-b8ee-dc98d031bf4d',
                    },
                    {
                        followup: 'b045e299-cf9c-433d-b8ee-dc98d031bf4d',
                    },
                ],
                answerType: 'choice',
                id: 'e157f6d5-a7b7-4df2-8291-4286caf78a91',
                includeOther: true,
                message: 'How will you know that your product is successful?',
                targetVariable: 'motivation',
            },
            {
                answers: [
                    {
                        followup: 'back',
                        text: 'Back',
                    },
                ],
                answerType: 'choice',
                dependency: 'e157f6d5-a7b7-4df2-8291-4286caf78a91',
                id: 'b045e299-cf9c-433d-b8ee-dc98d031bf4d',
                message: 'We don\'t currently understand goals of that type, although we\'re sure you\'re doing something great! All impact is currently measured in terms of quantifiable users or revenue.',
            },
            {
                answers: [
                    {
                        text: 'Ok',
                        followup: '78a700ed-61dc-4114-9994-79957f91163e',
                    },
                ],
                answerType: 'choice',
                dependency: 'e157f6d5-a7b7-4df2-8291-4286caf78a91',
                id: 'cc5dcacc-4dac-4e1b-84fb-7d75ef0a4419',
                message: 'We\'ve added your first milestone. A milestone is a specific result that you\'re trying to achieve, rather than a set of features that you plan to deliver.',
            },
            {
                answers: [
                    {
                        text: 'Yes',
                        followup: '7624f792-60bd-4b4e-89f1-ef18cd0389b0',
                    },
                    {
                        text: 'No',
                        followup: 'cbc986a3-376a-451c-b938-0f8389dac5db',
                    },
                ],
                answerType: 'choice',
                dependency: 'e157f6d5-a7b7-4df2-8291-4286caf78a91',
                id: '78a700ed-61dc-4114-9994-79957f91163e',
                message: 'Do you already have a public version of your product?',
                targetVariable: 'publicProduct',
            },
            {
                answers: [
                    {
                        followup: '37a78aee-d993-49c7-a360-86589d1c97d1',
                        text: 'Yes',
                    },
                    {
                        followup: '37a78aee-d993-49c7-a360-86589d1c97d1',
                        text: 'No',
                    },
                ],
                answerType: 'choice',
                dependency: 'e157f6d5-a7b7-4df2-8291-4286caf78a91',
                id: '7624f792-60bd-4b4e-89f1-ef18cd0389b0',
                message: 'Do you already have organic users?',
                targetVariable: 'usersExist',
            },
            {
                answers: [
                    {
                        text: 'Yes',
                        followup: '40f5d677-3dab-4d14-b759-349d198d0905',
                    },
                    {
                        text: 'No',
                        followup: '37a78aee-d993-49c7-a360-86589d1c97d1',
                    },
                ],
                answerType: 'choice',
                dependency: 'e157f6d5-a7b7-4df2-8291-4286caf78a91',
                id: 'cbc986a3-376a-451c-b938-0f8389dac5db',
                message: 'Do you have a prototype of your product?',
                targetVariable: 'prototypeExists',
            },
            {
                answers: [
                    {
                        followup: 'a7168bd8-770f-4421-92c4-64d021b468e1',
                        text: 'Yes',
                    },
                    {
                        followup: '37a78aee-d993-49c7-a360-86589d1c97d1',
                        text: 'No',
                    },
                ],
                answerType: 'choice',
                dependency: 'cbc986a3-376a-451c-b938-0f8389dac5db',
                id: '40f5d677-3dab-4d14-b759-349d198d0905',
                message: 'Is your prototype a good demonstration of the core idea of the program?',
                targetVariable: 'prototypeSelfPassed',
            },
            {
                answers: [
                    {
                        followup: '90f7ec50-773d-4750-b3e3-d9d9fd8af4f7',
                        text: 'Yes',
                    },
                    {
                        followup: '37a78aee-d993-49c7-a360-86589d1c97d1',
                        text: 'No',
                    },
                ],
                answerType: 'choice',
                dependency: '40f5d677-3dab-4d14-b759-349d198d0905',
                id: 'a7168bd8-770f-4421-92c4-64d021b468e1',
                message: 'Have you watched someone else use your prototype?',
                targetVariable: 'prototypeTested',
            },
            {
                answers: [
                    {
                        followup: '37a78aee-d993-49c7-a360-86589d1c97d1',
                        text: 'Yes',
                    },
                    {
                        followup: '37a78aee-d993-49c7-a360-86589d1c97d1',
                        text: 'No',
                    },
                ],
                answerType: 'choice',
                dependency: 'a7168bd8-770f-4421-92c4-64d021b468e1',
                id: '90f7ec50-773d-4750-b3e3-d9d9fd8af4f7',
                message: 'Was someone else able to use your prototype and understand the core idea?',
                targetVariable: 'prototypeUserTestPassed',
            },
            {
                answers: [
                    {
                        text: 'Ok',
                        followup: 'e6a3f9ba-2614-4fb0-bda8-7c7495baf28e',
                    },
                ],
                answerType: 'choice',
                id: '37a78aee-d993-49c7-a360-86589d1c97d1',
                message: 'We\'ve added a few more milestones for you. With milestones, remember to focus on results, not tasks.',
                targetVariable: 'milestonesExplained',
            },
            {
                answers: [
                    {
                        text: 'Ok',
                    },
                ],
                answerType: 'choice',
                id: 'e6a3f9ba-2614-4fb0-bda8-7c7495baf28e',
                message: 'Go ahead and edit your milestones so that they make more sense for your product. You\'ll be able to edit them more later.',
            },
        ])

        this.userState.watchVariableModification((name: string, value: any) => {
            const milestoneVariables = ['motivation', 'prototypeExists', 'prototypeSelfPassed', 'prototypeUserTestPassed', 'publicProduct', 'usersExist']
            if (milestoneVariables.includes(name)) {
                if (this.$store.getters.hasMilestoneWithKey(name)) {
                    return
                }
                if (name === 'motivation') {
                    const milestoneNames: { [key: string]: string } = { users: 'Lots of users', revenue: 'Plenty of revenue' }
                    if (value in milestoneNames) {
                        const milestone = new Milestone(uuid(), milestoneNames[value], false, 'motivation')
                        this.$store.commit('addMilestone', milestone)
                    }
                }

                if (name === 'publicProduct' && value !== 'yes') {
                    const milestone = new Milestone(uuid(), 'First organic user', false, 'usersExist')
                    this.$store.commit('insertMilestone', { milestone, beforeKey: 'motivation' })
                }

                if (name === 'prototypeExists') {
                    const milestone = new Milestone(uuid(), 'Finish a prototype that demonstrates the core idea well', false, 'prototypeExists')
                    this.$store.commit('insertMilestone', { milestone, beforeKey: 'usersExist' })
                }

                if (name === 'prototypeSelfPassed' && value === 'yes') {
                    const selfTestMilestone = this.$store.state.milestones.find((m: any) => m.key === 'prototypeExists')
                    if (selfTestMilestone) {
                        selfTestMilestone.completed = true
                    }
                }

                if (name === 'prototypeSelfPassed' || (name === 'prototypeExists' && value !== 'yes')) {
                    const userTestMilestone = new Milestone(uuid(), 'Complete a successful user test', false, 'prototypeUserTestPassed')
                    this.$store.commit('insertMilestone', { milestone: userTestMilestone, beforeKey: 'usersExist' })
                }

                if (name === 'prototypeUserTestPassed' && value === 'yes') {
                    const userTestMilestone = this.$store.state.milestones.find((m: any) => m.key === 'prototypeUserTestPassed')
                    if (userTestMilestone) {
                        userTestMilestone.completed = true
                    }
                }

                if (name === 'usersExist') {
                    const milestone = new Milestone(uuid(), 'First organic user', value === 'yes', 'usersExist')
                    this.$store.commit('insertMilestone', { milestone, beforeKey: 'motivation' })
                }
            } else if (name === 'milestonesExplained') {
                this.$store.commit('enableMilestoneEditing', true)
                this.$store.commit('enableHypothesisEditing', true)
            }
        })
    }

    private get message() {
        if (!this.activeScript) {
            return []
        }

        let { message } = this.activeScript
        const lastAnswerResponse = this.scriptRunner.getLastAnswerResponse()
        if (lastAnswerResponse) {
            message = lastAnswerResponse + message
        }
        // Split on new sentences and new lines.
        return message.replace(/([.?!])\s*(?=[A-Z])/g, '$1\n')
            .split('\n')
    }

    private get rawTextIsValid() {
        return this.otherAnswer.length > 0
    }

    private selectRawAnswer() {
        if (!this.rawTextIsValid) {
            return
        }

        this.scriptRunner.handleAnswer(this.otherAnswer)
        this.otherAnswer = ''
    }

    private selectAnswer(selectedAnswer: string) {
        this.scriptRunner.handleAnswer(selectedAnswer)
        this.otherAnswer = ''
    }

    private get visibleAnswers() {
        return this.activeScript?.answers?.filter((answer: any) => !!answer.text) ?? []
    }
}
