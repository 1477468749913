export default class Hypothesis {
    mitigated: boolean
    id: string
    name: string
    likely = true

    constructor(id: string, name: string, mitigated: boolean) {
        this.mitigated = mitigated
        this.id = id
        this.name = name
    }
}
