import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c0b7412c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "editable-name text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_popup_edit = _resolveComponent("q-popup-edit")!
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lHh Lpr lFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_header, { elevated: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, { class: "bg-grey-2 text-grey" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_toolbar_title, null, {
                default: _withCtx(() => [
                  (_ctx.productName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createElementVNode("div", {
                          class: "cursor-pointer text-caption",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'home' })))
                        }, " Impact driven development "),
                        _createElementVNode("div", _hoisted_2, [
                          _createTextVNode(_toDisplayString(_ctx.productName) + " ", 1),
                          _createVNode(_component_q_icon, {
                            class: "edit-icon",
                            name: "edit",
                            color: "blue"
                          }),
                          _createVNode(_component_q_popup_edit, {
                            modelValue: _ctx.productName,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.productName) = $event)),
                            validate: val => val.length > 0
                          }, {
                            default: _withCtx((scope) => [
                              _createVNode(_component_q_input, {
                                modelValue: scope.value,
                                "onUpdate:modelValue": ($event: any) => ((scope.value) = $event),
                                label: "Edit product name",
                                autofocus: "",
                                onFocus: _cache[1] || (_cache[1] = (input) => (input.target as any)?.select()),
                                filled: "",
                                rules: [(val) => val.length > 0 || 'A name is required.']
                              }, {
                                after: _withCtx(() => [
                                  _createVNode(_component_q_btn, {
                                    flat: "",
                                    dense: "",
                                    color: "negative",
                                    icon: "cancel",
                                    onClick: _withModifiers(scope.cancel, ["stop","prevent"])
                                  }, null, 8, ["onClick"]),
                                  _createVNode(_component_q_btn, {
                                    flat: "",
                                    dense: "",
                                    color: "positive",
                                    icon: "check_circle",
                                    onClick: _withModifiers(scope.set, ["stop","prevent"]),
                                    disable: scope.validate(scope.value) === false || scope.initialValue === scope.value
                                  }, null, 8, ["onClick", "disable"])
                                ]),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue", "rules"])
                            ]),
                            _: 1
                          }, 8, ["modelValue", "validate"])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(" Impact driven development ")
                      ], 64))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_page_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view),
          (!_ctx.hideDebugControls)
            ? (_openBlock(), _createBlock(_component_q_card, {
                key: 0,
                class: "debug-controls"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, { style: {"padding":"4px"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Debug: "),
                      (!_ctx.$store.getters.allFeaturesEnabled)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            flat: "",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$store.commit('enableAllFeatures')))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Enable all features")
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.$store.state.wizardEnabled)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 1,
                            flat: "",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => {_ctx.$store.commit('toggleWizard'); _ctx.$store.commit('enableAllFeatures')})
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Disable wizard")
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_q_btn, {
                            key: 2,
                            flat: "",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$store.commit('toggleWizard')))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Enable wizard")
                            ]),
                            _: 1
                          })),
                      _createVNode(_component_q_btn, {
                        flat: "",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.hideDebugControls = true))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Hide")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}