import SubAction from '@/SubAction'

export default class Action {
    completed: boolean
    description = ''
    hypothesisIds: string[] = []
    id: string
    important = true
    name: string
    subActions: SubAction[] = []

    constructor(id: string, name: string, completed: boolean) {
        this.completed = completed
        this.id = id
        this.name = name
    }
}
